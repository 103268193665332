<template>
  <div class="pb-9">
    <v-container>
      <v-layout wrap>
        <v-flex
          sm12
          md6
          offset-md3
        >
          <v-card
            elevation="4"
            light
            tag="section"
          >
            <v-card-title>
              <v-layout
                wrap
                justify-center
              >
                <h3 class="headline">
                  IAM Logs
                </h3>
              </v-layout>
            </v-card-title>
            <v-card-text>
              <v-label>
                <ul>
                  <li class="mb-2">
                    Enter Date Range and View Logs
                  </li>
                  <li class="mb-4">
                    Logs are accessible after 24 hours
                  </li>
                </ul>

                <br />
              </v-label>
              <v-form>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model="startDate"
                      outline
                      label="Start Date"
                      type="datetime-local"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="endDate"
                      outline
                      label="End Date"
                      type="datetime-local"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
              <v-card-actions :class="{ 'pa-3': $vuetify.breakpoint.smAndUp }">
                <v-spacer></v-spacer>
                <v-btn
                  :disabled="searchBtnDisabled"
                  color="primary"
                  @click="GetIAMLogsInDateRange()"
                >
                  Search
                </v-btn>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex
          sm12
          md6
          offset-md3
        >
          <v-layout
            align-center
            justify-space-between
          >
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
    <v-container>
      <div v-if="displayTest">
        <v-data-table
          :headers="IAMColumns"
          :items="IAMLogList"
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          item-key="id"
          show-expand
          class="elevation-1"
          loading="loadingList"
        >
          <template
            v-slot:top
          >
            <v-toolbar
              :elevation="8"
              color="primary"
              dark
              dense
              shaped
            >
              <v-toolbar-title>
                <strong>Logs Found</strong>
              </v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:expanded-items="{ headers, item }">
            <tr>
              <td>
                User: {{ item.userId }}
              </td>
              <td>
                Site: {{ item.siteId }}
              </td>
              <td>
                {{ item.WhatWasAccessed }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </v-container>
  </div>
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import useActivityLogsService from '@/store/useActivityLogsService'
import ActivityLogService from '@/services/ActivityLogService'

export default {
  setup() {
    const LOGS_APP = 'app-activity-logs'

    // Register module
    if (!store.hasModule(LOGS_APP)) {
      store.registerModule(LOGS_APP, ActivityLogService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(LOGS_APP)) store.unregisterModule(LOGS_APP)
    })

    const {
      loadingList,
      IAMColumns,
      IAMLogList,

      revertEntity,
      fetchIAMLogsByDate,
      test,
    } = useActivityLogsService()

    return {
      loadingList,
      IAMColumns,
      IAMLogList,

      revertEntity,
      fetchIAMLogsByDate,
      test,
    }
  },
  data() {
    const startDates = new Date()

    return {
      restoreBtnDisabled: false,
      searchBtnDisabled: false,
      displayTest: false,
      startDates,
      startDate: startDates,
      endDate: startDates,
      expanded: [],
      singleExpand: false,
    }
  },
  mounted() {
    this.test()
  },
  methods: {
    GetIAMLogsInDateRange() {
      this.loadingList = true
      this.searchBtnDisabled = true

      const datesDTO = {
        startDate: new Date(this.startDate),
        endDate: new Date(this.endDate),
      }
      console.log(`reached dates: ${JSON.stringify(datesDTO)}`)

      this.fetchIAMLogsByDate(datesDTO)
      this.displayTest = true
    },
  },
}
</script>

<style lang="scss" scoped>
.v-btn,
.v-card {
  border-radius: 4px;
  color: black;
}

.v-card__title {
  text-transform: uppercase;
}
</style>
