import ApiConfig from './ApiConfig'
import { ActivityLogsClient_V1 } from './generated/api.generated.v1'
import variables from '@/variables'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchActivityLogsByDate(context, datesDTO) {
      const api = new ActivityLogsClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getSiteLogsByDate(datesDTO)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    revertEntity(context, GetLogsDTO) {
      const api = new ActivityLogsClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .revertToReversionEntity(GetLogsDTO)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchIAMLogsByDate(context, datesDTO) {
      console.log(`reached fetchIAMLogsByDate: ${JSON.stringify(datesDTO)}`)
      const api = new ActivityLogsClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getIAMLogsByDate(datesDTO)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
